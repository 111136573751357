import * as Tooltip from '@radix-ui/react-tooltip';
import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind, TailwindContents} from '@wandb/weave/components/Tailwind';
import classNames from 'classnames';
import React from 'react';

import {useRunsLinePlotContext} from './RunsLinePlotContext/RunsLinePlotContext';
import {SAMPLING_FALLBACK_ERROR_MESSAGE} from './RunsLinePlotContext/useDetermineAggregationType';

const positions = ['bl', 'tl', 'br', 'tr'] as const;

export const SamplingWarning = React.memo(
  ({style, width = 100}: {style?: React.CSSProperties; width?: number}) => {
    // leaving this for now, would like to let users move this around to avoid it
    // overlaying important graph regions
    const [pos] = React.useState<(typeof positions)[number]>(positions[1]);
    const position = classNames('absolute inline-flex', {
      'top-4': pos.includes('t'),
      'left-[calc(50%-16px)]': pos.includes('l') && width > 250, // center the icon in the middle of panel header for large panels
      'left-[calc(30%)]': pos.includes('l') && width <= 250, // otherwise move it a bit to the left but not blocking the drag handle in the upper left corner
    });

    const {isSamplingFallback, warningMessages} = useRunsLinePlotContext();

    const pluralWarnings = warningMessages.length > 1;

    if (!isSamplingFallback) {
      return null;
    }

    // note: because the portal renders outside the heirarchy it needs its own Tailwind wrapper
    return (
      <Tailwind>
        <div className={position} style={style}>
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger className="bg-s inline-flex items-center justify-between gap-2 rounded bg-moon-150 px-4 py-4 text-xs focus-visible:outline-none">
                <Icon name="info" height={14} />
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content
                  side="top"
                  sideOffset={8}
                  collisionPadding={8}
                  style={{zIndex: 2147483606}} // Ugly, but this is the lowest value needed to get the tooltip to render above the modal
                >
                  <TailwindContents>
                    <div className="w-[340px] rounded bg-moon-900 p-12 text-sm leading-[18px] text-moon-200 shadow-md">
                      {warningMessages.length > 0 ? (
                        <div>
                          Full fidelity mode for point aggregation is in active
                          development. This panel is using sampling as the
                          following panel configuration
                          {pluralWarnings ? 's' : ''}{' '}
                          {pluralWarnings ? 'are' : 'is'} not yet supported:
                          <br />
                          <br />
                          {warningMessages.map((warning, i) => (
                            <div key={i}>- {warning}</div>
                          ))}
                          <br />
                          Remove {pluralWarnings ? 'these' : 'this'} option
                          {pluralWarnings ? 's' : ''} to visualize points in
                          full fidelity.
                        </div>
                      ) : (
                        SAMPLING_FALLBACK_ERROR_MESSAGE
                      )}
                    </div>
                  </TailwindContents>
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>
      </Tailwind>
    );
  }
);
SamplingWarning.displayName = 'SamplingWarning';
