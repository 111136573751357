import {useDeepMemo} from '@wandb/weave/hookUtils';
import _ from 'lodash';
import {useEffect, useMemo, useReducer, useState} from 'react';

import {logError} from '../../../services/errors/errorReporting';
import {Query, RunSetQuery} from '../../../util/queryTypes';
import {
  useRampFlagUseBackendGrouping,
  useRampFlagUseBackendSmoothing,
} from '../../../util/rampFeatureFlags';
import {keyToServerPath} from '../../../util/runs';
import {useSharedPanelState} from '../../Panel/SharedPanelStateContext';
import {POINT_VISUALIZATION_OPTIONS} from '../../WorkspaceDrawer/Settings/types';
import {Range, runsLinePlotTransformQuery} from '../common';
import {usePanelConfigContext} from '../PanelConfigContext';
import {usePanelGroupingSettings} from '../RunsLinePlotContext/usePanelGroupingSettings';
import {RunsLinePlotConfig} from '../types';
import {getRunSets} from '../utils/getRunSets';
import {useDeepEqualValue} from './../../../util/hooks';
import {bucketedRunsReducer} from './bucketedDataReducer';
import {BucketedQueryState} from './bucketedQueryManager';
import {bracketHistoriesData, mapEnabledByRunSets} from './util';

const usePanelQuery = (
  pageQuery: Query,
  config: RunsLinePlotConfig,
  queryZoomRange: Range,
  skip?: boolean
) => {
  const {limit: numRuns, parsedExpressions} = usePanelConfigContext();
  const enableBackendSmoothing = useRampFlagUseBackendSmoothing(
    pageQuery.entityName
  );

  const transformed = skip
    ? null
    : runsLinePlotTransformQuery({
        query: pageQuery,
        runsLinePlotConfig: config,
        xStepRange: queryZoomRange,
        parsedExpressions,
        defaultMaxRuns: numRuns,
        isFullFidelity: true,
        enableBackendSmoothing,
      });
  return useDeepMemo(transformed);
};

function useRunSets(pageQuery: Query) {
  const runSets = useMemo(() => getRunSets(pageQuery), [pageQuery]);
  const runSetsById = mapEnabledByRunSets(runSets);
  const deepEqualEnabledRunSets = useDeepEqualValue(runSetsById);

  return deepEqualEnabledRunSets;
}

/*
There are four types of queries here:
The "WithInternalId" queries are used when the runset being queried is not tied to the workspace/report being viewed,
  so we don't have access to the projectId or entityId.
The "Grouped" queries are for cases where backend grouping is supported, so we will attempt to do aggregation in the
  backend and skip it in the frontend.
*/
const getQueryType = (
  usesInternalId: boolean,
  isGrouped: boolean,
  runSets: RunSetQuery[],
  enableBackendGrouping: boolean
) => {
  const useGroupedQuery =
    enableBackendGrouping && runSets.length < 2 && isGrouped;
  return usesInternalId
    ? useGroupedQuery
      ? 'GroupedBucketedQueryWithInternalId'
      : 'BucketedQueryWithInternalId'
    : useGroupedQuery
    ? 'GroupedBucketedQuery'
    : 'BucketedQuery';
};

export function useBucketedData(
  config: RunsLinePlotConfig,
  pageQuery: Query,
  queryZoomRange: Range,
  nBuckets: number,
  skip?: boolean
) {
  const {isAnyRunsetGrouped, getGroupKeysByRunsetId} =
    usePanelGroupingSettings();
  const panelQuery = usePanelQuery(pageQuery, config, queryZoomRange, skip);
  const {bucketQueryManagerById} = useSharedPanelState();

  const runSets = useRunSets(pageQuery);

  const [isAggregated, setIsAggregated] = useState<Record<string, boolean>>({});

  const groupKeys = useMemo(() => {
    return pageQuery?.runSets != null
      ? pageQuery.runSets.flatMap(runSet => {
          return getGroupKeysByRunsetId(runSet.id);
        })
      : [];
  }, [pageQuery?.runSets, getGroupKeysByRunsetId]);
  const groupKeyNames = useMemo(() => {
    return groupKeys.map(keyToServerPath);
  }, [groupKeys]);

  const [bucketedRunsState, bucketedRunsDispatch] = useReducer(
    bucketedRunsReducer,
    {
      runSetsById: runSets,
      runDataById: {},
    }
  );

  const enableBackendGrouping = useRampFlagUseBackendGrouping(
    pageQuery.entityName
  );

  const panelQueryWithGroupKeys = useMemo(() => {
    if (!panelQuery) {
      return null;
    }
    return {
      ...panelQuery,
      groupKeys: groupKeyNames,
      isGrouped: isAnyRunsetGrouped,
    };
  }, [panelQuery, groupKeyNames, isAnyRunsetGrouped]);

  // TODO: it seems like this is firing even though runSets should be stable. Not a big deal because the reducer
  // handles the trash, but need to figure this out.
  useEffect(() => {
    bucketedRunsDispatch({
      type: 'bucketedRuns/setRunSetEnabled',
      payload: runSets,
    });
  }, [runSets]);

  useEffect(() => {
    if (!panelQueryWithGroupKeys) {
      return;
    }
    const managers = bucketQueryManagerById.current;
    const handlers: ((state: BucketedQueryState) => void)[] = [];

    panelQueryWithGroupKeys.queries.forEach(q => {
      const usesInternalID = !!q.internalProjectId;

      const queryType = getQueryType(
        usesInternalID,
        isAnyRunsetGrouped,
        pageQuery?.runSets ?? [],
        enableBackendGrouping
      );

      const handlerDataChange = (state: BucketedQueryState) => {
        const bracketedState = bracketHistoriesData(state, config.xAxis);
        bucketedRunsDispatch({
          type: 'bucketedRuns/setRunDataById',
          id: q.id,
          data: bracketedState,
        });
        if (state.data) {
          const newIsAggregated = {
            ...isAggregated,
            [q.id]: state.data.isAggregated,
          };

          if (!_.isEqual(newIsAggregated, isAggregated)) {
            setIsAggregated(newIsAggregated);
          }
        }
      };

      handlers.push(handlerDataChange);

      const matchedQueryManager = bucketQueryManagerById.current[q.id];
      if (matchedQueryManager) {
        bucketQueryManagerById.current[q.id].registerRequest({
          handler: handlerDataChange,
          nBuckets,
          runsDataQuery: panelQueryWithGroupKeys,
          singleQuery: q,
          expressions: config.expressions,
          queryType,
        });
      } else {
        const {queries, ...pQuery} = panelQueryWithGroupKeys;

        const error = new ReferenceError(
          'Full fidelity error: no bucketed query manager found for query'
        );
        const context = {
          bucketedQueryManager: Object.keys(
            bucketQueryManagerById.current
          ).join(', '),
          panelQuery: pQuery,
          queries: q,
        };
        // @ts-ignore Sentry can accept key/value pairs
        logError(error, context);
      }
    });

    // Cleanup function that unregisters all handlers
    return () => {
      panelQueryWithGroupKeys.queries.forEach((q, index) => {
        if (managers[q.id]) {
          managers[q.id].unregisterRequest(handlers[index]);
        }
      });
    };
  }, [
    nBuckets,
    panelQuery,
    bucketQueryManagerById,
    config.xAxis,
    isAnyRunsetGrouped,
    config.expressions,
    groupKeyNames,
    isAggregated,
    enableBackendGrouping,
    pageQuery?.runSets,
    panelQueryWithGroupKeys,
  ]);

  const bucketedDataMemo = useMemo(() => {
    return {
      _dataType: POINT_VISUALIZATION_OPTIONS.BucketingGorilla,
      entityName: pageQuery.entityName,
      histories: {
        data: Object.keys(bucketedRunsState.runDataById)
          .filter(key => bucketedRunsState.runSetsById[key] ?? false)
          .flatMap(key => {
            const data = bucketedRunsState.runDataById[key];
            try {
              const result = Object.values(data.data?.runsById ?? {}).filter(
                r => !!r
              );
              return result;
            } catch (e) {
              console.error(e);
              return [];
            }
          }),
      },
      initialLoading: false,
      loadMore: () => {},
      projectName: pageQuery.projectName,
      isAggregated,
    };
  }, [
    pageQuery.entityName,
    pageQuery.projectName,
    bucketedRunsState,
    isAggregated,
  ]);

  const {error, loading} = useMemo(() => {
    return {
      error: Object.values(bucketedRunsState.runDataById).find(v => v.error),
      loading: Object.values(bucketedRunsState.runDataById).some(
        v => v.loading
      ),
    };
  }, [bucketedRunsState.runDataById]);

  if (error) {
    console.error(error);
  }

  /**
   * IMPORTANT!
   *
   *
   * Generates a cache key for bucketed data results.
   * This key is used to uniquely identify the data in the cache.
   * It should be updated whenever any of the dependencies that affect
   * the data query change.
   *
   * Do not change the cache key here unless you know what you are doing.
   * The cache key should be the panelQuery. If you need to update the cache key
   * to add any additional dependencies to trigger invalidation, add them to
   * the panelQuery.
   */

  return useMemo(
    () => ({
      data: bucketedDataMemo,
      error,
      loading,
      key: panelQueryWithGroupKeys,
    }),
    [bucketedDataMemo, error, loading, panelQueryWithGroupKeys]
  );
}
