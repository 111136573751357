/**
 * This file tracks events specific to product.
 */

import {SmoothingType} from '../../generated/graphql';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {makeTrackEvent} from './makeTrackEvent';
import {ActivationMethods, NoEventData} from './types';

export const enum ProdEventNames {
  AppLauncherFailure = 'app-launcher-failure', // tracks non-executable app launcher attempts
  AppLauncherUsed = 'app-launcher-used', // tracks each time the app launcher is used to navigate
  AppLauncherOnScreen = 'app-launcher-on-screen', // tracks each time the app launcher is triggered
  RunGroupNodeViewed = 'Run Group Node Viewed', // tracks each time a user views a run group node in the DAG
  SearchNavOnScreen = 'search-nav-on-screen', // tracks each time the search nav is triggered
  SearchNavUsed = 'search-nav-used', // tracks each time the search nav is used to navigate
  SmoothingChanged = 'Smoothing changed', // tracks each time smoothing is applied to a plot
  TempViewNavigation = 'Temporary Workspace View Bar Navigation Triggered', // tracks each time the user navigates to a temp view
  MediaSliderMetricKeySelected = 'Media slider metric key selected', // tracks each time a user selects a metric key other than _step to navigate through a media panel
  MediaSliderMetricKeyRemoved = 'Media slider metric key removed', // tracks each time a user selects the default, _step, metric after previously selecting a different metric
}

export type QuickNavData = {
  activationMethod: ActivationMethods;
  destination: string;
  location: string;
  type: 'option-selected' | 'direct-navigation';
};

export type QuickNavDescriptions = {
  activationMethod: {
    description: 'Whether the action was triggered by keyboard shortcut or mouse';
    exampleValues: [
      ActivationMethods.MouseClick,
      ActivationMethods.KeyboardHotkey
    ];
  };
  destination: {
    description: 'The new app location being navigated to';
    exampleValues: ['/home', '/[entityName]'];
  };
  location: {
    description: 'The current location within the application';
    exampleValues: ['/home', '/[entityName]'];
  };
  type: {
    description: 'This determines if a user found a matching option in the results list or if they successfully navigated to a location by typing the URL directly';
    exampleValues: ['option-selected', 'direct-navigation'];
  };
};

export const withinLastFilterAdded = makeTrackEvent<
  NoEventData,
  {
    _description: 'Fires once when the user adds the "within last" op to a filter';
    _location: 'Workspace > edit filters UI';
    _motivation: "We want to track usage of this new feature we've added";
  }
>('User added Within Last Filter');
export const withinLastFilterRemoved = makeTrackEvent<
  NoEventData,
  {
    _description: 'Fires once when the user removes the "within last" op from a filter';
    _location: 'Workspace > edit filters UI';
    _motivation: "We want to track usage of this new feature we've added";
  }
>('User removed Within Last Filter');

export const appLauncherFailure = makeTrackEvent<
  Pick<QuickNavData, 'destination' | 'location'>,
  QuickNavDescriptions & {
    _description: 'A user has tried to enter a search term in the launcher nav that does not link to a destination';
    _location: 'Global - App launcher bar';
    _motivation: 'Identify destinations users desire but we do not support';
  }
>(ProdEventNames.AppLauncherFailure);

export const appLauncherUsed = makeTrackEvent<
  Omit<QuickNavData, 'activationMethod'>,
  QuickNavDescriptions & {
    _description: 'A successful entry in the app launcher leading to a new destination';
    _location: 'Global - App launcher bar';
    _motivation: 'Identify where users quickly navigate to';
  }
>(ProdEventNames.AppLauncherUsed);

export const appLauncherOnScreen = makeTrackEvent<
  Pick<QuickNavData, 'activationMethod' | 'location'>,
  QuickNavDescriptions & {
    _description: 'The app launcher widget has become visible in the app';
    _location: 'Global - App launcher bar';
    _motivation: 'Track usage / ease of discovery';
  }
>(ProdEventNames.AppLauncherOnScreen);

export const searchNavOnScreen = makeTrackEvent<
  Pick<QuickNavData, 'activationMethod' | 'location'>,
  QuickNavDescriptions & {
    _description: 'The search nav dropdown is being used';
    _location: 'Global - Header nav search input';
    _motivation: 'Track usage of search in header nav';
  }
>(ProdEventNames.SearchNavOnScreen);

export const searchNavUsed = makeTrackEvent<
  Pick<QuickNavData, 'destination' | 'location'>,
  QuickNavDescriptions & {
    _description: 'A successful entry in the search nav leading to a new destination';
    _location: 'Global - Header nav search input';
    _motivation: 'Identify where users quickly navigate to';
  }
>(ProdEventNames.SearchNavUsed);

export const pointCloudViewed = makeTrackEvent<
  {
    type: 'fullscreen' | 'preview';
  },
  {
    type: {
      description: 'is the point cloud being viewed inside of the workspace panel as a preview, or has the user clicked to view the pointcloud fullscreen?';
      exampleValues: ['fullscreen', 'preview'];
    };
  } & {
    _description: 'fired for each instance of a point cloud - eg if 5 point cloud previews are rendered (visible in any part of html then this will fire 5 times. Full screen will fire once per time the user clicks to open full screen mode';
    _location: 'Workspaces > Media Panel > Point Cloud viewer';
    _motivation: 'How many folks are using the point cloud viewer?';
  }
>('3D Point Cloud Viewed');

export const pointCloudFileWorkaroundUsed = makeTrackEvent<
  NoEventData,
  {
    _description: 'There was a bug in the way the SDK was submitting files, this tracks when we have to use the workaround to view those files correctly. See detailed discussion in https://github.com/wandb/wandb/pull/4353';
    _location: 'Object3DCard';
    _motivation: 'Do we even need to keep this workaround in the code?';
  }
>('3D Point Cloud Viewed, required workaround');

type SmoothingEventData = {
  entityName: string;
  panelId?: string;
  projectName: string;
  smoothingType: SmoothingType;
};

export const smoothingChanged = makeTrackEvent<
  SmoothingEventData,
  {
    _description: 'A user has changed the smoothing settings in a chart';
    _location: 'A ';
    _motivation: 'Track usage of this feature';
    entityName: {
      description: 'The entity the project belongs to';
      exampleValues: ['my-org', 'my-user'];
    };
    panelId: {
      description: 'The panel ID if';
      exampleValues: ['', ''];
    };
    projectName: {
      description: 'The project being viewed';
      exampleValues: ['my-project'];
    };
    smoothingType: {
      description: 'The smoothing algorithm being applied';
      exampleValues: [
        SmoothingType.None,
        SmoothingType.Gaussian,
        SmoothingType.ExponentialTimeWeighted
      ];
    };
  }
>(ProdEventNames.SmoothingChanged);

export const viewRunGroupNode = makeTrackEvent<
  {
    entityName: string;
    projectName: string;
    artifactCollectionName: string;
    artifactCommitHash: string;
    artifactTab: string;
    artifactTypeName: string;
    numRuns: number;
  },
  {
    _description: 'A user has clicked on a run group node in DAG view';
    _location: 'Simple lineage view in the artifact DAG';
    _motivation: 'Track usage of this feature';
    entityName: {
      description: 'The entity currently viewing the workspace';
      exampleValues: ['my-org', 'my-user'];
    };
    projectName: {
      description: 'The project being viewed';
      exampleValues: ['my-project'];
    };
    artifactCollectionName: {
      description: 'The artifact being viewed';
      exampleValues: ['MNIST_test'];
    };
    artifactCommitHash: {
      description: 'The hash of the artifact';
      exampleValues: ['v0'];
    };
    artifactTab: {
      description: 'The active tab in the artifact view';
      exampleValues: ['lineage'];
    };
    artifactTypeName: {
      description: 'The type of artifact being viewed';
      exampleValues: ['dataset'];
    };
    numRuns: {
      description: 'The number of runs in the run group';
      exampleValues: [5];
    };
  }
>(ProdEventNames.RunGroupNodeViewed);

export const trackPlotKeyboardCopy = makeTrackEvent<
  NoEventData,
  {
    _description: 'This event fires when the user is looking at a plot, has hovered over the plot and presses the key combo to copy the contents of the legeng to the clipboard';
    _location: 'Anywhere plot panel can be rendered - Workspaces/Reports usually';
    _motivation: 'We created this feature and want to understand if anyone is using it';
  }
>('Plot legend data copied to clipboard');

export const trackMinMaxAggregation = makeTrackEvent<
  {
    type: 'latest' | 'min' | 'max';
  },
  {
    type: {
      description: 'the aggregation viewed by the user';
      exampleValues: ['latest', 'min', 'max'];
    };
  } & {
    _description: 'This event fires when the user selects an option in the aggregation dropdown';
    _location: 'In the dropdown for a column in the runs table';
    _motivation: 'Understand how useful this feature is to users';
  }
>('Min/Max aggregation selected');

export const trackAddedPanelGridToReport = makeTrackEvent<
  NoEventData,
  {
    _description: 'Fires when a panelgrid is added to a report.';
    _location: 'Multiple, including: Project page, report page, anywhere create report prompt can be shown';
    _motivation: 'Fullstory cue - understand how reports are being used';
  }
>('Runset panel grid added to report');

export const trackAddPanelMenuClosed = makeTrackEvent<
  NoEventData,
  {
    _description: 'Fires after the user has clicked the Add Panel button, selected item, and the menu is now closing';
    _location: 'report page, panel grid action buttons';
    _motivation: 'Fullstory cue - understand how reports are being used';
  }
>('Add Panel Menu Closed');

export const trackImportPanelMenuClosed = makeTrackEvent<
  NoEventData,
  {
    _description: 'Fires after the user has clicked the Import Panel button, selected a project+panel, and the menu is now closing';
    _location: 'report page, panel grid action buttons';
    _motivation: 'Fullstory cue - understand how reports are being used';
  }
>(`Import Panel Menu Closed`);

export const trackAddRunSetToPanelGrid = makeTrackEvent<
  NoEventData,
  {
    _description: 'Fires after the user has clicked the + button to add a run set to the panel grid';
    _location: 'report page, panel grid';
    _motivation: 'Fullstory cue - understand how reports are being used';
  }
>('Add Run Set Clicked');

export const trackWorkspaceSettingsOpened = makeTrackEvent<
  {
    entityName: string;
    projectName: string;
    workspaceID: string;
    user: string;
  },
  {
    entityName: {
      description: 'The entity the project belongs to';
      exampleValues: ['fellowshipAI'];
    };
    projectName: {
      description: 'The project being viewed';
      exampleValues: ['destroy-ring'];
    };
    workspaceID: {
      description: 'The workspace being viewed';
      exampleValues: ['workspace-6k4mybvm2'];
    };
    user: {
      description: 'The user viewing the workspace';
      exampleValues: ['bilbobaggins'];
    };
  } & {
    _description: 'User opened workspace settings modal';
    _location: 'workspaces panelbank';
    _motivation: 'see usage of existing feature';
  }
>('Workspace settings modal viewed');

export const trackPanelSearchHistoryOpened = makeTrackEvent<
  NoEventData,
  {
    _description: 'When the panel search history button is clicked, we show a dropdown menu - this tracks when that occurs';
    _location: 'workspaces panelbank';
    _motivation: 'new UI added, track usage';
  }
>('Panel search history menu opened');

export const trackPanelSearchCleared = makeTrackEvent<
  NoEventData,
  {
    _description: 'When the user clicks the clear button located inside of the panel search box';
    _location: 'workspaces panelbank';
    _motivation: 'new UI added, track usage';
  }
>('Panel search cleared');

export const trackPanelSearchHistoryItemSelected = makeTrackEvent<
  {index: number},
  {
    _description: 'When the user clicks an item in the panel search history dropdown';
    _location: 'workspaces panelbank';
    _motivation: 'new UI added, track usage';
    index: {
      description: 'The zero-based index of the item in the list that was clicked by the user';
      exampleValues: [0, 10];
    };
  }
>('Panel search history item selected');

export const trackPanelSearchAutoExpandToggled = makeTrackEvent<
  {value: boolean},
  {
    _description: 'User updated the auto-expand setting during panel search';
    _location: 'workspaces panelbank';
    _motivation: 'new UI added, track usage';
    value: {
      description: 'Whether auto-expand is on or off';
      exampleValues: [true, false];
    };
  }
>('Panel search auto-expand toggled');

export const mediaSliderMetricKeySelected = makeTrackEvent<
  {
    metricKey: string;
    entityName: string;
    projectName: string;
    user?: string;
    panelId?: string;
  },
  {
    _description: 'Fired when a user selects a metric key other than _step to navigate through a media panel';
    _location: 'Workspaces > Media Panel > Settings';
    _motivation: 'How many folks are using a metric other than step to navigate through a media panel?';
    metricKey: {
      description: 'The metric key that was selected';
      exampleValues: ['epoch', 'checkpoint'];
    };
    entityName: {
      description: 'The entity the project belongs to';
      exampleValues: ['my-org', 'my-user'];
    };
    projectName: {
      description: 'The project being viewed';
      exampleValues: ['my-project'];
    };
    user: {
      description: 'The user viewing the workspace';
      exampleValues: ['bilbobaggins'];
    };
    panelId: {
      description: 'The panel ID if set in query parameters';
      exampleValues: ['', ''];
    };
  }
>(ProdEventNames.MediaSliderMetricKeySelected);

export const mediaSliderMetricKeyRemoved = makeTrackEvent<
  {
    previousMetricKey: string;
    entityName: string;
    projectName: string;
    user?: string;
    panelId?: string;
  },
  {
    _description: 'Fired when a user selects the default, _step, metric after previously selecting a different metric';
    _location: 'Workspaces > Media Panel > Settings';
    _motivation: 'How many users opt to go back to navigating by step after selecting another metric?';
    previousMetricKey: {
      description: 'The metric key that was removed';
      exampleValues: ['epoch', 'checkpoint'];
    };
    entityName: {
      description: 'The entity the project belongs to';
      exampleValues: ['my-org', 'my-user'];
    };
    projectName: {
      description: 'The project being viewed';
      exampleValues: ['my-project'];
    };
    user: {
      description: 'The user viewing the workspace';
      exampleValues: ['bilbobaggins'];
    };
    panelId: {
      description: 'The panel ID if set in query parameters';
      exampleValues: ['', ''];
    };
  }
>(ProdEventNames.MediaSliderMetricKeyRemoved);
