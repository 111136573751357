import {SelectAsync} from '@wandb/weave/components/Form/Select';
import {debounce} from 'lodash';
import React, {useCallback} from 'react';

import {SettingSelectOptionType} from '../types';

const MAX_OPTIONS_DISPLAYED = 100;

export type LinePlotXAxisProps<OptionType extends SettingSelectOptionType> = {
  id?: string;
  className?: string;
  options: OptionType[];
  value: OptionType | undefined;
  onChange: (selected: OptionType | null) => void;
};

/**
 * This is a Select component used in the settings drawer.
 * It is searchable and will truncate options to a max list of 100
 * to provide better UX experience for workspaces with a large
 * set of metrics.
 */
export const SettingSelect = <OptionType extends SettingSelectOptionType>({
  id,
  className,
  onChange,
  options,
  value,
}: LinePlotXAxisProps<OptionType>) => {
  const loadOptions = useCallback(
    (inputValue: string, callback: (options: OptionType[]) => void) => {
      const searchText = inputValue.trim();
      if (searchText === '') {
        callback(truncateOptions(options));
        return;
      }

      const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
      );
      callback(truncateOptions(filteredOptions));
    },
    [options]
  );

  const debouncedLoadOptions = debounce(loadOptions, 300);

  return (
    <SelectAsync<OptionType>
      id={id}
      cacheOptions
      className={className}
      defaultOptions
      formatOptionLabel={({label}) => (
        <div role="option">
          <span>{label}</span>
        </div>
      )}
      isSearchable
      loadOptions={debouncedLoadOptions}
      onChange={onChange}
      options={options}
      value={value}
    />
  );
};

const truncateOptions = <OptionType extends SettingSelectOptionType>(
  options: OptionType[]
) => {
  return options.slice(0, MAX_OPTIONS_DISPLAYED);
};
