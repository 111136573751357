import {SmoothingType} from '../../generated/graphql';

export type SmoothingOptionMeta = {
  displayName: string; // shows up in the UI
  iconName: string; // configures what Icon is displayed
  // defaults is what seeds the smoothing input controls
  sortOrder: number; // controls what order they display in the dropdown
  defaults: {
    default: number;
    min: number;
    max: number;
    step: number;
  };
};

export const smoothingOptionsMeta: Record<SmoothingType, SmoothingOptionMeta> =
  {
    [SmoothingType.None]: {
      displayName: 'No smoothing',
      iconName: 'no-smooth',
      sortOrder: 0, // TODO - remove this since it doesn't seem necessary
      // these won't be used, but the UI is visible & disabled
      defaults: {
        default: 0,
        min: 0,
        max: 0.999,
        step: 0.001,
      },
    },
    [SmoothingType.ExponentialTimeWeighted]: {
      displayName: 'Time weighted EMA',
      iconName: 'time-weighted-ema',
      sortOrder: 1,
      defaults: {
        default: 0,
        min: 0,
        max: 0.99,
        step: 0.01,
      },
    },
    [SmoothingType.Average]: {
      displayName: 'Running average',
      iconName: 'running-average',
      sortOrder: 2,
      defaults: {
        default: 10,
        min: 1,
        max: 100,
        step: 1,
      },
    },
    [SmoothingType.Gaussian]: {
      displayName: 'Gaussian',
      iconName: 'gaussian',
      sortOrder: 3,
      defaults: {
        default: 1,
        min: 0,
        max: 100,
        step: 1,
      },
    },
    [SmoothingType.Exponential]: {
      displayName: 'Exponential moving average (deprecated)',
      iconName: 'ema',
      sortOrder: 4,
      defaults: {
        default: 0,
        min: 0,
        max: 0.99,
        step: 0.01,
      },
    },
  };
