import {createAction} from 'typesafe-actions';

import {RunSelectorRunsQuery} from '../../generated/graphql';
import * as RunTypes from '../../util/runTypes';
export {deleteRun, updateRun, updateRunTags} from '../runs-low/actions';

export type RunSelectorRunsData = {
  lastUpdatedAt: string;
  runs: RunTypes.Run[];
  totalRuns: number;
};

type QueryResultsData = NonNullable<
  NonNullable<RunSelectorRunsQuery['project']>['runs']
>;

export const processQueryResults = createAction(
  '@runSelectorRuns/processQueryResults',
  action => (queryId: string, data: QueryResultsData) => action({queryId, data})
);

export const removeQuery = createAction(
  '@runSelectorRuns/removeQuery',
  action => (queryId: string) => action({queryId})
);

export const loadRunSelectorRunsFromCache = createAction(
  '@runSelectorRuns/loadRunSelectorRunsFromCache',
  action => (queryId: string, data: RunSelectorRunsData) =>
    action({queryId, data})
);
