import {DEFAULT_LINE_PLOT_MAX_NUM_BUCKETED_RUNS} from '../../WorkspaceDrawer/Settings/runLinePlots/maxRunDefaults';
import {RunsLinePlotConfig} from '../types';
import {serverFlagNoNonMonotonicFallback} from './../../../config';

export function configSupportsBucketing(
  config: {
    groupAgg?: RunsLinePlotConfig['groupAgg'];
    groupArea?: RunsLinePlotConfig['groupArea'];
    isMonotonic?: boolean;
    isInReport?: boolean;
    xAxis?: RunsLinePlotConfig['xAxis'];
    limit?: number;
  },
  /**
   * This is an override which is added because non-monotonic detection is broken in V2 and forcing customers back to sampling.
   * https://weightsandbiases.slack.com/archives/G01BLMX3H61/p1728491743432009
   */
  serverFlag = serverFlagNoNonMonotonicFallback
) {
  const warnings = [];

  if (
    config.isInReport &&
    config.limit &&
    config.limit > DEFAULT_LINE_PLOT_MAX_NUM_BUCKETED_RUNS
  ) {
    warnings.push(
      `max runs limit over ${DEFAULT_LINE_PLOT_MAX_NUM_BUCKETED_RUNS}`
    );
  }

  /**
   * We don't support all the different kinds of group aggregations yet in ff mode
   */
  if (['stderr', 'stddev', 'samples'].includes(config.groupArea ?? '')) {
    warnings.push('unsupported group aggregation');
  }
  /**
   * When a panel comes in with previously configured grouping options we eject that back to the old RSDQ for now. Very few panels have this configuration, so we're delaying conversion of this for now.
   *
   * For how these properties work see comment in `derviedLinesConfiguration.ts` for more context
   */

  // non-monotonic x
  // _absolute_runtime is a constructed special case - as such it's not in the returned list of historykeys and so it fails the isMonotonic check when we look them up
  if (
    !config.isMonotonic &&
    config.xAxis !== '_absolute_runtime' &&
    !serverFlag()
  ) {
    warnings.push('non-monotonic x-axis');
  }

  return warnings;
}
