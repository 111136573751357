import * as _ from 'lodash';

import {LinesFromDataProps} from '../../components/PanelRunsLinePlot/types';
import {isDatetimeAxisFormat} from '../../components/PanelRunsLinePlot/utils/isDatetimeAxisFormat';
import {SmoothingType} from '../../generated/graphql';
import {legendTemplateRemoveCrosshairValues} from '../legend';
import {smooth} from '../math';
import {Key} from '../runTypes';
import {color} from './../colors';
import {RunWithRunsetInfoAndHistory} from './../runhelpers';
import {
  getValue,
  getValueSafe,
  groupedRunDisplayName,
  keyToString,
} from './../runs';
import {aggregateLines} from './aggregateLines';
import {getBucketSpec} from './buckets/getBucketSpec';
import {filterNegative} from './plotHelpers';
import {PointsByRunAndMetric} from './pointsByRunAndMetric';
import * as Time from './time';
import {AggregateCalculation, AxisInfoType, Bar, Line, Point} from './types';
import {packageUnionLines} from './unionLines';

const maxHistoryKeyCount = 16;

export function convertLineToBar(line: Line) {
  // Used for when we create lines in lineplot but realize we actually want a bar plot
  // Need to translate the lines into bars

  const key =
    line.title != null
      ? legendTemplateRemoveCrosshairValues(line.title)
      : line.displayName ?? line.name ?? '';

  return {
    ...line,
    key,
    value: line.data[0].y,
    stddev: line.stddevLine?.stddev,
    range:
      line.minmaxLine != null
        ? [line.minmaxLine.data[0]?.y0, line.minmaxLine.data[0]?.y]
        : undefined,
  } as Bar;
}

export function smoothLine(
  line: Line,
  smoothingParam: number,
  smoothingType: SmoothingType,
  xAxisInfo?: AxisInfoType
): Line {
  if (line.smoothedLineFromBackend != null) {
    return line.smoothedLineFromBackend;
  }
  const [pointsX, pointsY] = line.data.reduce(
    (acc, p) => {
      acc[0].push(p.x);
      acc[1].push(p.y);
      return acc;
    },
    [[], []] as [number[], number[]]
  );

  const xAxis = {
    ...xAxisInfo,
    xValues: pointsX,
  };
  const pointsSmoothedY = smooth(pointsY, xAxis, smoothingParam, smoothingType);

  return {
    ...line,
    smoothed: true,
    meta: {
      ...line.meta,
      category: 'smoothed',
    },
    data: line.data.map((_, i) => {
      return {
        x: pointsX[i],
        y: pointsSmoothedY[i],
        legendData: {
          original: pointsY[i],
        },
      };
    }),
  };
}

/**
 * Returns the min and max point from a list of numbers as a tuple
 */
export function getMinMaxFromArray(n: number[]): [min: number, max: number] {
  return [Math.min(...n), Math.max(...n)];
}

/**
 * Given multiple lines of points, returns total scale of the x units
 * i.e. the minimum x value from any line and the maximum x value from any line
 */
export function getXAxisInfo(lines: Line[]): AxisInfoType {
  const xVals = lines
    .map(line => {
      const pointXVals = line.data.map(point => point.x);
      return getMinMaxFromArray(pointXVals);
    }, [])
    .flat();
  const [min, max] = getMinMaxFromArray(xVals);
  return {min, max};
}

/**
 * Takes an array of lines and returns a new smoothed line for each
 * Line in the array.  Lightens the color of the original lines.
 */
export function smoothLines(
  lines: Line[],
  smoothingParam: number,
  smoothingType: SmoothingType
) {
  // if the smoothing type is exponential time weighted we need to determine the plotted range
  let xAxisInfo: AxisInfoType;
  if (
    smoothingType === SmoothingType.ExponentialTimeWeighted ||
    smoothingType === SmoothingType.Gaussian
  ) {
    xAxisInfo = getXAxisInfo(lines);
  }
  // only ever smooth the non-aux line
  const smoothedLines: Line[] = lines.map(line =>
    line.aux ? line : smoothLine(line, smoothingParam, smoothingType, xAxisInfo)
  );

  return [lines.map(l => ({...l, aux: true})), smoothedLines];
}

export function linesFromSystemMetricsPlot(
  events: string[],
  eventKeys: string[], // list of keys for find in events data structure
  xAxis: string,
  smoothingParam: number,
  smoothingType: SmoothingType = SmoothingType.Exponential,
  yAxisLog = false
) {
  const maxEventKeyCount: number = maxHistoryKeyCount;

  const eventNames = eventKeys
    .filter(lineName => !_.startsWith(lineName, '_') && !(lineName === 'epoch'))
    .slice(0, maxEventKeyCount);

  const eventLines = eventNames
    .map((lineName, i) => {
      const lineData = events
        .map(
          (row, j) =>
            ({
              // __index is a legacy name - we should remove it from the logic
              // here at some point.
              x:
                xAxis === '__index' || xAxis === '_step'
                  ? j
                  : Number(row[xAxis as any]),
              y: Number(row[lineName as any]),
            } as Point)
        )
        .filter(
          point =>
            !_.isNil(point.x) &&
            !_.isNil(point.y) &&
            !_.isNaN(point.x) &&
            !_.isNaN(point.y)
        );
      return {
        color: color(i + maxHistoryKeyCount),
        colorIndex: i + maxHistoryKeyCount,
        data: lineData,
        meta: {
          aggregation: 'none',
          category: 'default',
          minMaxOnHover: 'unused',
          mode: 'sampled',
          type: 'line',
        },
        title: lineName,
      } as Line;
    })
    .filter(line => line.data.length > 0);

  const lines = eventLines;

  // TODO: The smoothing should probably happen differently if we're in log scale
  let allLines: Line[] = [];
  if (smoothingType !== SmoothingType.None && smoothingParam > 0) {
    const [origLines, smoothedLines] = smoothLines(
      lines,
      smoothingParam,
      smoothingType
    );
    allLines = _.concat(smoothedLines, origLines);
  } else {
    allLines = lines;
  }

  if (yAxisLog) {
    allLines = filterNegative(allLines);
  }

  /**
   * System metrics don't have a timestep value
   */
  if (Time.isXAxisTimeKey(xAxis)) {
    Time.mutateLinesByTimeType[xAxis](allLines);
  }

  return allLines;
}

/**
 * Takes in data points in runsetrun format and returns lines.
 * Also does smoothing and bucketing.
 *
 * Inputs
 * data - data structure with all the runs
 * key - yAxis value - if this is an array we are grouping over multiple yAxis values
 * xAxis - xAxis value
 * smoothingParam - (number [0,1])
 * groupKeys - what config parameters should we aggregate by
 * yAxisLog - is the yaxis log scale - this is to remove non-positive values pre-plot
 */
export type RootConfigArgs = Pick<
  LinesFromDataProps,
  | 'aggregateCalculations'
  | 'groupArea'
  | 'groupLine'
  | 'showOriginalAfterSmoothing'
  | 'smoothingParam'
  | 'smoothingType'
  | 'xAxis'
  | 'xAxisFormat'
  | 'windowing'
>;
export type DerivedArgs = {
  extraVars: Key[];
  groupKeys: Key[];
  metricNames: string[];
  runsetRuns: RunWithRunsetInfoAndHistory[];
  useMedian: boolean;
  yAxisLog: boolean;
};
export function linesFromRunsets(
  configFromRoot: RootConfigArgs,
  derivedConfig: DerivedArgs,
  pointsByRunAndMetric: PointsByRunAndMetric
) {
  if (derivedConfig.runsetRuns.length === 0) {
    return {lineCount: 0, lines: []};
  }

  let lines: Line[] = [];
  // Typically there is just one key passed in.
  // Multiple keys happens when user wants to aggregate over multiple metrics.
  lines = derivedConfig.metricNames.flatMap(metricName => {
    return derivedConfig.runsetRuns.map(runsetRun => {
      const {points, nanPoints} = pointsByRunAndMetric?.[runsetRun.id]?.[
        metricName
      ] ?? {points: [], nanPoints: []};

      const vars: {[key: string]: number} = {};
      derivedConfig.extraVars.forEach(varKey => {
        vars[keyToString(varKey)] = Number(getValueSafe(runsetRun, varKey));
      });

      return {
        data: points,
        displayName: runsetRun.displayName,
        meta: {
          aggregation: 'none',
          category: 'default',
          minMaxOnHover: 'unused',
          mode: 'sampled',
          type: 'line',
        },
        name: runsetRun.name,
        nanPoints,
        run: runsetRun,
        title: '',
        type: 'line',
        vars,
      };
    });
  });

  let lineCount = lines.length;

  lines.forEach(line => {
    line.data.sort((a, b) => {
      return a.x - b.x;
    });

    // remove duplicate x values
    line.data = line.data.filter((d, i) => {
      return i === line.data.length - 1 || d.x !== line.data[i + 1].x;
    });
  });

  if (isDatetimeAxisFormat(configFromRoot.xAxis, configFromRoot.xAxisFormat)) {
    Time.convertTimestampLinesToMilliseconds(lines);
  }

  const useBucketing =
    derivedConfig.groupKeys.length > 0 || derivedConfig.metricNames.length > 1;

  if (useBucketing) {
    // If we're sampling we bucket the data into buckets of xaxis values
    // now do this regardless of number of points and regardless of x-axis key
    const groupedLines = _.groupBy(lines, l =>
      derivedConfig.groupKeys.length > 0
        ? // Can use not-null type assertion because we know we set l.run above.
          JSON.stringify(
            derivedConfig.groupKeys.map(gKey => getValue(l.run!, gKey))
          )
        : l.name
    );
    lines = mapAggregateLines({
      configFromRoot,
      derivedConfig,
      lines: groupedLines,
    });

    lineCount = lines.filter(l => !l.aux).length;
  }

  let allLines;
  if (configFromRoot.smoothingParam > 0) {
    const [origLines, smoothedLines] = smoothLines(
      lines,
      configFromRoot.smoothingParam,
      configFromRoot.smoothingType
    );
    allLines = // when bucketing is active never show the original
      // this isn't ideal but we can't disambiguate well enough in sampling mode
      (useBucketing ? false : configFromRoot.showOriginalAfterSmoothing)
        ? _.concat(smoothedLines, origLines)
        : smoothedLines;
  } else {
    allLines = lines;
  }

  if (derivedConfig.yAxisLog) {
    allLines = filterNegative(allLines);
  }

  const toReturn = {
    lines: allLines,
    lineCount,
  };
  return toReturn;
}

function mapAggregateLines({
  configFromRoot,
  derivedConfig,
  lines,
}: {
  configFromRoot: RootConfigArgs;
  derivedConfig: DerivedArgs;
  lines: _.Dictionary<Line[]>;
}) {
  return _.flatMap(lines, gLines => {
    if (gLines.length === 0) {
      return [];
    }
    // groupKeysStr is the nice display name for the group when the user configures
    // the legend tab. This is not what gets persisted in the panel config.
    const groupKeysStr =
      gLines[0].run != null
        ? groupedRunDisplayName(gLines[0].run, derivedConfig.groupKeys)
        : '';

    const bucketSpec = getBucketSpec(gLines);

    const calculate: Record<AggregateCalculation, boolean> = {
      minmax: configFromRoot.aggregateCalculations.includes('minmax'),
      stderr: configFromRoot.aggregateCalculations.includes('stderr'),
      stddev: configFromRoot.aggregateCalculations.includes('stddev'),
      samples: configFromRoot.aggregateCalculations.includes('samples'),
      mean: true,
      none: false,
    };
    if (!configFromRoot.windowing) {
      const aggLines = packageUnionLines({
        lines: gLines,
        name: groupKeysStr,
        line: configFromRoot.groupLine,
        calculate,
        extraVars: derivedConfig.extraVars,
      });
      return [
        aggLines.meanLine,
        aggLines.minmaxLine,
        aggLines.stddevLine,
        aggLines.stderrLine,
        aggLines.sampleLines,
      ]
        .flat()
        .filter(l => l != null) as Line[];
    }

    const allAggLines = aggregateLines({
      lines: gLines,
      bucketSpec,
      name: groupKeysStr,
      aggregateCalculations: calculate,
      useMedian: derivedConfig.useMedian,
      extraVars: derivedConfig.extraVars,
    });
    return [
      allAggLines.meanLine,
      allAggLines.minmaxLine,
      allAggLines.stddevLine,
      allAggLines.stderrLine,
      allAggLines.sampleLines,
    ]
      .flat()
      .filter(l => l != null) as Line[];
  });
}
