import {SmoothingType} from '../../generated/graphql';
import {RunsLinePlotConfigDefaults} from './types';

// see notes on handling time plots
// Readme: frontends/app/src/components/PanelRunsLinePlot/timePlots/readme.md
// Demo: https://wandb.ai/public-team-private-projects/Time%20plots?nw=36sx43brn2r
export const X_AXIS_LABELS: {[key: string]: string} = {
  _step: 'Step',
  _absolute_runtime: 'Relative Time (Wall)',
  _runtime: 'Relative Time (Process)',
  _timestamp: 'Wall Time',
};

/**
 * This is not an exhaustive list of defaults, but rather a WIP list of values that were defaulted in one or more places through PanelRunsLinePlot. Moving the defaults to a shared value for consistency.
 */
export const runsLinePlotConfigDefaults: RunsLinePlotConfigDefaults = {
  aggregateMetrics: false,
  groupAgg: 'mean',
  groupArea: 'minmax',
  legendFields: [],
  metrics: [],
  plotType: 'line',
  showOriginalAfterSmoothing: true,
  smoothingType: SmoothingType.Exponential,
  smoothingWeight: 0.0,
  useRunsTableGroupingInPanels: true,
  windowing: true,
};
