import React, {useCallback, useMemo} from 'react';
import {twMerge} from 'tailwind-merge';

import {SmoothingType} from '../../../../generated/graphql';
import {SettingType} from '../../../../services/analytics/workspaceSettingsEvents';
import {
  calculateIsSettingEnabled,
  SettingOverrideCounts,
} from '../../../../state/views/workspaceSettings/settingOverrideUtils';
import {smoothingOptionsMeta} from '../../../elements/SmoothingConfig';
import {ClearSettingsRows} from '../components/ClearSettingsRow';
import {ResetSettingButton} from '../components/ResetSettingButton';
import {SettingLabel} from '../components/SettingLabel';
import {SettingSelect} from '../components/SettingSelect';
import {SettingSlider} from '../components/SettingSlider';
import {useClearSettingRows} from '../hooks/useClearSettingRows';
import {ClearSettingFnType} from '../hooks/useSettingOverrides';
import {LinePlotSettings, SettingLevel} from '../types';
import {DEFAULT_SMOOTHING_SETTINGS} from './linePlotDefaults';

export const EMPTY_SMOOTHING_SETTINGS = {
  smoothingType: undefined,
  smoothingWeight: undefined,
};

type SmoothingSelectOption = {
  value: SmoothingType;
  label: string;
};

type SmoothingMetadataType = {
  defaultValue: number;
  min: number;
  max: number;
  step: number;
};

export type LinePlotSmoothingProps = {
  className?: string;
  clearSetting: ClearSettingFnType;
  /**
   * These are the values that are displayed to the user. We want to show the
   * inherited values when relevant.
   */
  displayValues: {
    smoothingType?: SmoothingType;
    smoothingWeight?: number;
  };
  numOverrides?: SettingOverrideCounts;
  settingLevel: SettingLevel;
  /**
   * These are the values that match in the view spec depending on the setting level.
   */
  values: {
    smoothingType?: SmoothingType;
    smoothingWeight?: number;
  };
  trackSetting: (setting: SettingType, value: string | undefined) => void;
  updateSetting: (
    settings: Pick<LinePlotSettings, 'smoothingType' | 'smoothingWeight'>
  ) => void;
};

export const LinePlotSmoothing = ({
  className,
  clearSetting,
  displayValues,
  numOverrides,
  settingLevel,
  values,
  trackSetting,
  updateSetting,
}: LinePlotSmoothingProps) => {
  const smoothingOptions = useMemo(() => getSmoothingOptions(), []);

  const smoothingTypeOption = useMemo(() => {
    if (displayValues.smoothingType == null) {
      return {
        value: DEFAULT_SMOOTHING_SETTINGS.smoothingType,
        label: getSmoothingTypeDisplayName(
          DEFAULT_SMOOTHING_SETTINGS.smoothingType
        ),
      };
    }
    return {
      value: displayValues.smoothingType,
      label: getSmoothingTypeDisplayName(displayValues.smoothingType),
    };
  }, [displayValues.smoothingType]);

  const onSmoothingTypeChange = useCallback(
    (selected: SmoothingSelectOption | null) => {
      if (selected != null) {
        updateSetting({
          smoothingType: selected.value,
          smoothingWeight: getSmoothingMetadata(selected).defaultValue, // reset
        });
        trackSetting('smoothingType', selected.value.toString());
      }
    },
    [trackSetting, updateSetting]
  );

  const clearSettingRows = useClearSettingRows({
    clearSetting,
    emptySettings: EMPTY_SMOOTHING_SETTINGS,
    numOverrides,
    settingKey: 'smoothing',
    settingLevel,
  });

  const isSmoothingTypeNone = smoothingTypeOption.value === SmoothingType.None;

  return (
    <div className={twMerge('flex flex-col', className)}>
      <div className="flex w-full">
        <SettingLabel text="Smoothing" className="shrink-0" />
        <div className="item-center flex w-full flex-row gap-8">
          <SettingSelect
            className="grow"
            options={smoothingOptions}
            value={smoothingTypeOption}
            onChange={onSmoothingTypeChange}
          />
          <ResetSettingButton
            isEnabled={calculateIsSettingEnabled('smoothing', values)}
            onButtonClick={() =>
              clearSetting(settingLevel, 'smoothing', EMPTY_SMOOTHING_SETTINGS)
            }
            settingLevel={settingLevel}
          />
        </div>
      </div>
      <div
        className={twMerge(
          'flex flex-col',
          'group-[.is-panel-config]:text-sm', // account for panel config - font size is smaller in there than slider
          isSmoothingTypeNone ? '' : 'ml-[7rem] mt-12'
        )}>
        {!isSmoothingTypeNone && (
          <SmoothingSettingSlider
            smoothingWeight={displayValues.smoothingWeight}
            smoothingTypeOption={smoothingTypeOption}
            updateSetting={updateSetting}
            trackSetting={trackSetting}
          />
        )}
        <div
          className={twMerge(
            isSmoothingTypeNone && clearSettingRows.length > 0 ? 'mt-12' : ''
          )}>
          <ClearSettingsRows rows={clearSettingRows} />
        </div>
      </div>
    </div>
  );
};

export const SmoothingSettingSlider = ({
  smoothingWeight,
  smoothingTypeOption,
  updateSetting,
  trackSetting,
}: {
  smoothingWeight: number | undefined;
  smoothingTypeOption: SmoothingSelectOption;
  updateSetting: (settings: Partial<LinePlotSettings>) => void;
  trackSetting: (setting: SettingType, value: string | undefined) => void;
}) => {
  const {min, max, step, defaultValue} = useMemo(
    () => getSmoothingMetadata(smoothingTypeOption),
    [smoothingTypeOption]
  );

  const updateSettingValue = useCallback(
    (newVal: number) => {
      updateSetting({
        smoothingWeight: newVal,
      });
      trackSetting('smoothingWeight', newVal.toString());
    },
    [trackSetting, updateSetting]
  );

  return (
    <SettingSlider
      dataTest="line-plot-smoothing-slider"
      name="smoothing"
      min={min}
      max={max}
      step={step}
      sliderValue={smoothingWeight == null ? defaultValue : smoothingWeight}
      updateSetting={updateSettingValue}
    />
  );
};

export const getSmoothingTypeDisplayName = (smoothingType: SmoothingType) => {
  return smoothingOptionsMeta[smoothingType].displayName;
};

const getSmoothingOptions = (): SmoothingSelectOption[] => {
  return Object.keys(smoothingOptionsMeta).map(val => ({
    label: getSmoothingTypeDisplayName(val as SmoothingType),
    value: val as SmoothingType,
  }));
};

const getSmoothingMetadata = (
  smoothingOption: SmoothingSelectOption
): SmoothingMetadataType => {
  const data = smoothingOptionsMeta[smoothingOption.value].defaults;
  return {
    defaultValue: data.default,
    min: data.min,
    max: data.max,
    step: data.step,
  };
};
